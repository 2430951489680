import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/episode-layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ep4---jacob-tomlinson-on-becoming-an-open-source-software-engineer"
    }}>{`Ep4 - Jacob Tomlinson: On becoming an open source software engineer`}</h1>
    <div className="soundcloud-iframe">
    <iframe className="mb-0" width="100%" height="20" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/884483407&color=%23fc4056&inverse=true&auto_play=false&show_user=true"></iframe>
    </div>
    <Link className="link-button mr-5" to="/transcripts/ep4-jacob-tomlinson/" mdxType="Link">Transcript</Link>
    <a className="link-button" href="https://youtu.be/IJPYRCJGYrU">Watch this talk</a>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`On this episode, we talk with Jacob Tomlinson, a senior software engineer at Nvidia.  Jacob takes us through his journey into becoming a software engineer working on open source.`}</p>
    <p>{`We talk about opsdroid, the async chat-ops bot framework that Jacob created, the importance of hackathons on Jacob's journey and also about his experience from working with a big team at the MET office to a remote position at Nvidia.`}</p>
    <p>{`Jacob also shared how his working day looks like, the importance of setting deadlines on your todo list, his experience whilst hiring folks for positions and how to best write a CV to land that tech interview.`}</p>
    <h2 {...{
      "id": "guest-information"
    }}>{`Guest Information`}</h2>
    <div className="episode-card">
    <img src="https://landingintech.com/jacob-tomlinson.jpg" alt="Jacob Tomlinson" className="guest-avatar" />
    <div className="mx-8 pt-5">
        <p className="episode-title text-xl">Jacob Tomlinson</p>
        <p>Senior Software Engineer</p>
        <p>
            <a href="https://jacobtomlinson.dev/"> Website</a> | <a href="https://github.com/jacobtomlinson"> Github</a> | <a href="https://twitter.com/_JacobTomlinson"> Twitter</a>
        </p>
    </div>
    </div>
    <h2 {...{
      "id": "resources-and-links"
    }}>{`Resources and Links`}</h2>
    <p>{`Through the talk we have mentioned a lot of interesting projects, books, videos and other resources, you can find all about them in this section.`}</p>
    <p><em parentName="p"><strong parentName="em">{`Note`}</strong>{`: Any link to amazon is an affiliate link which means if you buy something from the link, it will not cost you more but we get paid a percentage from amazon which helps with the expenses of running the show.`}</em></p>
    <h3 {...{
      "id": "open-source-projects"
    }}>{`Open Source projects`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/opsdroid"
        }}>{`opsdroid`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/dask"
        }}>{`Dask`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/rapidsai"
        }}>{`Rapids`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/pytest-dev/pytest/"
        }}>{`Pytest`}</a></li>
    </ul>
    <h3 {...{
      "id": "hiringinterviews"
    }}>{`Hiring/Interviews`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/2GchCYj"
        }}>{`Book: Joy Inc.`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=uQdy914JRKQ"
        }}>{`Youtube: How to crack a Google coding interview `}</a></li>
    </ul>
    <h3 {...{
      "id": "other-resources"
    }}>{`Other Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://coiled.io/"
        }}>{`Coiled`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://todoist.com/"
        }}>{`Todoist`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      